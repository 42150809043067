import _ from "lodash";

const generator = (blockName) => (elementName = "", modificators = []) => {
  const renamedElementName =
    blockName + (elementName ? "__" + elementName : "");
  let renamedModificators = "";

  modificators.forEach((oneModificator) => {
    oneModificator &&
      (renamedModificators += " " + renamedElementName + "--" + oneModificator);
  });
  return renamedElementName + renamedModificators;
};

const createBemNameGenerator = (moduleNameKebabCase, componentFilepath) => {
  const [groupName, componentName] = componentFilepath.split("/").slice(-2);

  const blockName =
    moduleNameKebabCase +
    "__" +
    _.kebabCase(groupName) +
    "__" +
    _.kebabCase(componentName.replace(/\.jsx/i, ""));

  return generator(blockName);
};

export default createBemNameGenerator;

import React from "react";
import ErrorBoundary from "common/components/ErrorBoundary";
import { moduleInfo } from "../../config";
import createBemNameGenerator from "common/helpers/createBemNameGenerator";
import { NavLink } from "react-router-dom";

const NavigationLinks = ({ links = [] }) => {
  require("." +
    __filename.substr(__filename.lastIndexOf("/")).replace(/.jsx$/i, ".scss"));

  const bemNameGenerator = createBemNameGenerator(
    moduleInfo.nameKebabCase,
    __filename
  );

  return (
    <ErrorBoundary>
      <nav className={bemNameGenerator()}>
        {links.map(({ name, path, label, isExact }) => {
          const props = { key: name, to: path, exact: isExact };
          return (
            <NavLink {...props} className={bemNameGenerator("link")}>
              {label}
            </NavLink>
          );
        })}
      </nav>
    </ErrorBoundary>
  );
};

export default NavigationLinks;

import React from "react";
import ErrorBoundary from "common/components/ErrorBoundary";
import { moduleInfo } from "../../config";
import createBemNameGenerator from "common/helpers/createBemNameGenerator";
import { socialNetworkLinks } from "../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BurgerMenu from "./BurgerMenu";

const SocialNetworkLinks = ({ burgerAdditionalLinks = [] }) => {
  require("." +
    __filename.substr(__filename.lastIndexOf("/")).replace(/.jsx$/i, ".scss"));

  const bemNameGenerator = createBemNameGenerator(
    moduleInfo.nameKebabCase,
    __filename
  );

  return (
    <ErrorBoundary>
      <div className={bemNameGenerator()}>
        {Object.entries(socialNetworkLinks).map(
          ([key, { icon, link, title, type }]) => {
            const linkProps = {
              href: link,
              key,
              title,
              type,
              target: "_blank",
              rel: "noopener noreferrer",
            };

            !linkProps.type && delete linkProps.type;

            return (
              <a {...linkProps} className={bemNameGenerator("link")}>
                <FontAwesomeIcon icon={icon} />
              </a>
            );
          }
        )}
        <BurgerMenu
          navigationLinks={burgerAdditionalLinks}
          socialNetworkLinks={socialNetworkLinks}
          className={bemNameGenerator("burger-menu")}
        />
      </div>
    </ErrorBoundary>
  );
};

export default SocialNetworkLinks;

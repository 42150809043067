import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ErrorPage from "../ErrorPage";
import { moduleInfo, navigationConfig, errorPageData } from "../../config";
import createBemNameGenerator from "common/helpers/createBemNameGenerator";
import Header from "../Header";
import Footer from "../Footer";

const App = () => {
  require("." +
    __filename.substr(__filename.lastIndexOf("/")).replace(/.jsx$/i, ".scss"));

  const bemNameGenerator = createBemNameGenerator(
    moduleInfo.nameKebabCase,
    __filename
  );

  const routes = {};

  const importRoutes = (r) => {
    r.keys().forEach((oneModulePath) => {
      const moduleName = /\w+/.exec(oneModulePath)[0];
      const { route: oneRoute } = r(oneModulePath);
      !!oneRoute && (routes[moduleName] = oneRoute);
    });
  };

  if (process.env.NODE_ENV === "test") {
    const { lstatSync, readdirSync } = require("fs");
    const path = "./src";
    readdirSync(path).forEach((itemName) => {
      if (
        lstatSync(path + "/" + itemName).isDirectory() &&
        /^(?!core$)(?!common$)(\w+)$/.test(itemName)
      ) {
        const { route } = require("../../../" + itemName);
        !!route && (routes[itemName] = route);
      }
    });
  } else {
    importRoutes(
      require.context(
        "../../..",
        true,
        /^(\.\/)(?!core\/$)(?!common\/$)(\w+)\/$/
      )
    );
  }

  routes.errorPage = { ...errorPageData, component: ErrorPage };
  const navigationLinks = navigationConfig.map(
    (oneModuleName) => routes[oneModuleName]
  );

  return (
    <BrowserRouter>
      <div className={bemNameGenerator()}>
        <Header navigationLinks={navigationLinks} />
        <div>
          <Switch>
            {Object.values(routes).map(
              ({ isExact, path, component: Component }) => {
                return (
                  <Route
                    exact={isExact}
                    path={path}
                    component={Component}
                    key={path}
                  />
                );
              }
            )}
          </Switch>
        </div>
        <Footer />
      </div>
    </BrowserRouter>
  );
};

export default App;

import React from "react";
import ErrorBoundary from "common/components/ErrorBoundary";
import { moduleInfo } from "../../config";
import createBemNameGenerator from "common/helpers/createBemNameGenerator";
import { socialNetworkLinks as socialNetworkLinksConfig } from "core/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";

const SocialNetworks = ({ data = {}, className = "" }) => {
  require("." +
    __filename.substr(__filename.lastIndexOf("/")).replace(/.jsx$/i, ".scss"));

  const bemNameGenerator = createBemNameGenerator(
    moduleInfo.nameKebabCase,
    __filename
  );

  const items = [];

  Object.keys(socialNetworkLinksConfig).forEach((key) => {
    const socialNetworkUrl = data[key + "Url"];
    !!socialNetworkUrl &&
      items.push({
        href: socialNetworkUrl,
        key,
        icon: socialNetworkLinksConfig[key].icon,
        title: "Share on " + _.capitalize(key),
        target: "_blank",
        rel: "noopener noreferrer",
      });
  });

  return (
    <ErrorBoundary>
      <div className={bemNameGenerator() + " " + className}>
        {items.map((props) => {
          const { icon, ...linkProps } = props;

          return (
            <a {...linkProps} className={bemNameGenerator("link")}>
              <FontAwesomeIcon icon={icon} />
            </a>
          );
        })}
      </div>
    </ErrorBoundary>
  );
};

export default SocialNetworks;

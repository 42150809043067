import React from "react";
import ErrorBoundary from "common/components/ErrorBoundary";
import { moduleInfo, navigationItems } from "../../config";
import { Link } from "react-router-dom";
import createBemNameGenerator from "common/helpers/createBemNameGenerator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";

const Navigation = ({
  currentItemNumber = 0,
  publications = [],
  className = "",
}) => {
  require("." +
    __filename.substr(__filename.lastIndexOf("/")).replace(/.jsx$/i, ".scss"));

  const bemNameGenerator = createBemNameGenerator(
    moduleInfo.nameKebabCase,
    __filename
  );
  const totalItemsNumber = publications.length;

  const navigationItemsPopulated = _.cloneDeep(navigationItems);

  const { first, previous, next, last, infoDisplay } = navigationItemsPopulated;

  const getId = (buttonName) => {
    let index = currentItemNumber;
    switch (buttonName) {
      case "first": {
        index = 0;
        break;
      }
      case "previous": {
        index--;
        break;
      }
      case "next": {
        index++;
        break;
      }
      case "last": {
        index = totalItemsNumber - 1;
        break;
      }
      default: {
        index = 0;
      }
    }
    return !!publications[index]
      ? publications[index].id
      : publications[currentItemNumber].id;
  };

  first.disabled = previous.disabled = currentItemNumber <= 0;
  last.disabled = next.disabled = currentItemNumber >= totalItemsNumber - 1;

  infoDisplay.text = `${currentItemNumber + 1} of ${totalItemsNumber}`;
  return (
    <ErrorBoundary>
      <div className={bemNameGenerator() + " " + className}>
        {Object.keys(navigationItemsPopulated).map((key) => {
          const { icon, text, disabled } = navigationItemsPopulated[key];
          if (key === "infoDisplay") {
            return (
              <span className={bemNameGenerator("text")} key={key}>
                {text}
              </span>
            );
          }
          return (
            <Link
              className={bemNameGenerator("button", [key])}
              to={moduleInfo.path.replace(":id", getId(key))}
              disabled={disabled}
              key={key}
            >
              <FontAwesomeIcon icon={icon} />
            </Link>
          );
        })}
      </div>
    </ErrorBoundary>
  );
};

export default Navigation;

import React, { useState, useEffect } from "react";
import ErrorBoundary from "common/components/ErrorBoundary";
import { moduleInfo } from "../../config";
import createBemNameGenerator from "common/helpers/createBemNameGenerator";

const Image = ({ url = "" }) => {
  require("." +
    __filename.substr(__filename.lastIndexOf("/")).replace(/.jsx$/i, ".scss"));

  const bemNameGenerator = createBemNameGenerator(
    moduleInfo.nameKebabCase,
    __filename
  );

  const [imageIsLoaded, setImageIsLoaded] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [timeOutId, setTimeOutId] = useState("");

  useEffect(() => {
    clearTimeout(timeOutId);
    if (imageIsLoaded) {
      setImageIsLoaded(false);
      const timeOut = setTimeout(() => {
        setImageUrl(url);
      }, 300);
      setTimeOutId(timeOut);
    } else {
      setImageUrl(url);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return (
    <ErrorBoundary>
      <div className={bemNameGenerator()}>
        <img
          className={bemNameGenerator("image", [
            imageIsLoaded ? "loaded" : "unloaded",
          ])}
          src={imageUrl}
          alt=""
          onLoad={() => setImageIsLoaded(true)}
        />
      </div>
    </ErrorBoundary>
  );
};

export default Image;

import React, { useState, useEffect } from "react";
import ErrorBoundary from "common/components/ErrorBoundary";
import { moduleInfo } from "../../config";
import createBemNameGenerator from "common/helpers/createBemNameGenerator";
import ReactMarkdown from "react-markdown";
import { getAboutPageData } from "core/provider";

const About = () => {
  require("." +
    __filename.substr(__filename.lastIndexOf("/")).replace(/.jsx$/i, ".scss"));

  const bemNameGenerator = createBemNameGenerator(
    moduleInfo.nameKebabCase,
    __filename
  );
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [imageIsLoaded, setImageIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoadingData(true);
    getAboutPageData()
      .then(({ data }) => {
        setIsLoadingData(false);
        setTitle(data.title);
        setText(data.text);
        setImageUrl(data.imageUrl);
      })
      .catch((error) => {
        setIsLoadingData(false);
        alert("Error: failed connection with the server");
      });

    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorBoundary>
      <div className={bemNameGenerator()}>
        {isLoadingData && (
          <h3 className={bemNameGenerator("loading-text")}>Loading...</h3>
        )}
        {!!title && (
          <>
            <h1 className={bemNameGenerator("title")}>{title}</h1>
            <ReactMarkdown className={bemNameGenerator("text")}>
              {text}
            </ReactMarkdown>
            {!!imageUrl && (
              <img
                src={imageUrl}
                onLoad={() => setImageIsLoaded(true)}
                className={bemNameGenerator("image", [
                  imageIsLoaded ? "loaded" : "unloaded",
                ])}
                alt=""
              />
            )}
          </>
        )}
      </div>
    </ErrorBoundary>
  );
};

export default About;

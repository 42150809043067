import React, { Component } from "react";
import createBemNameGenerator from "../../helpers/createBemNameGenerator";
import { moduleInfo } from "../../config";
import "./ErrorBoundary.scss";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: {},
      info: "",
    };
  }
  static getDerivedStateFromError(error) {
    return { hasError: true, error: error };
  }
  componentDidCatch(error, { componentStack }) {
    this.setState({ info: componentStack });
    console.error(error, componentStack);
    alert("Error: please check the console log");
  }

  render() {
    const bemNameGenerator = createBemNameGenerator(
      moduleInfo.nameKebabCase,
      __filename
    );
    return this.state.hasError ? (
      <div className={bemNameGenerator()}>
        <h3 className={bemNameGenerator("title")}>Error</h3>
        <pre className={bemNameGenerator("details")}>{this.state.info}</pre>
      </div>
    ) : (
      this.props.children
    );
  }
}

export default ErrorBoundary;

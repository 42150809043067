import {
  faUndoAlt,
  faStepBackward,
  faCaretLeft,
  faStepForward,
  faCaretRight,
} from "@fortawesome/free-solid-svg-icons";
import path from "path";
import _ from "lodash";

export const relativePath = path.relative(process.cwd(), __dirname);
export const id = relativePath.split("/")[1];

export const moduleInfo = {
  name: id,
  nameKebabCase: _.kebabCase(id),
  label: "Publication",
  path: "/" + id + "/:id",
  isExact: true,
};

export const backButton = { icon: faUndoAlt, title: "Go Back to Homepage" };

export const navigationItems = {
  first: { icon: faStepBackward },
  previous: {
    icon: faCaretLeft,
  },
  infoDisplay: {},
  next: {
    icon: faCaretRight,
  },
  last: {
    icon: faStepForward,
  },
};

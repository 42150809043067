import React from "react";
import ErrorBoundary from "common/components/ErrorBoundary";
import { moduleInfo } from "../../config";
import SocialNetworkLinks from "./SocialNetworkLinks";
import NavigationLinks from "./NavigationLinks";
import createBemNameGenerator from "common/helpers/createBemNameGenerator";
import { Link } from "react-router-dom";
import logo from "common/assets/logo.png";

const Header = ({ navigationLinks = [] }) => {
  require("." +
    __filename.substr(__filename.lastIndexOf("/")).replace(/.jsx$/i, ".scss"));

  const bemNameGenerator = createBemNameGenerator(
    moduleInfo.nameKebabCase,
    __filename
  );

  return (
    <ErrorBoundary>
      <header className={bemNameGenerator()}>
        {/*<h1 className={bemNameGenerator("title")}>Coolans Planet</h1>*/}
        <Link to="/">
          <img src={logo} alt="" className={bemNameGenerator("logo")} />
        </Link>
        <NavigationLinks links={navigationLinks} />
        <SocialNetworkLinks burgerAdditionalLinks={navigationLinks} />
      </header>
    </ErrorBoundary>
  );
};

export default Header;

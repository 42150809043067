import axios from "axios";
import {
  getPublicationsUrl,
  getAboutPageDataUrl,
  getHomePageCoverUrl,
  getFooterDataUrl,
} from "./config";
import fakeApiFactory from "./helpers/fakeApiFactory";
import getPublicationsMock from "./__mocks__/getPublications";
import getAboutPageDataMock from "./__mocks__/getAboutPageData";
import getHomePageCoverMock from "./__mocks__/getHomePageCover";
import getFooterDataMock from "./__mocks__/getFooterData";

const production = {
  getPublications: () => axios.get(getPublicationsUrl),
  getAboutPageData: () => axios.get(getAboutPageDataUrl),
  getHomePageCover: () => axios.get(getHomePageCoverUrl),
  getFooterData: () => axios.get(getFooterDataUrl),
};

const development = {
  getPublications: fakeApiFactory(getPublicationsMock),
  getAboutPageData: fakeApiFactory(getAboutPageDataMock),
  getHomePageCover: fakeApiFactory(getHomePageCoverMock),
  getFooterData: fakeApiFactory(getFooterDataMock),
};

const environments = { production, development };
const currentEnvironment = process.env.NODE_ENV;

export const getPublications =
  environments[currentEnvironment]["getPublications"];

export const getAboutPageData =
  environments[currentEnvironment]["getAboutPageData"];

export const getHomePageCover =
  environments[currentEnvironment]["getHomePageCover"];

export const getFooterData = environments[currentEnvironment]["getFooterData"];

import episode1 from "./episode1.png";
import episode2 from "./episode2.png";
import episode3 from "./episode3.png";
import episode4 from "./episode4.png";
import episode5 from "./episode5.png";
import episode6 from "./episode6.png";
import episode7 from "./episode7.png";
import episode8 from "./episode8.png";
import episode9 from "./episode9.png";
import episode10 from "./episode10.png";

const publications = [
  {
    id: 1,
    publicationDate: "2020-10-19T11:00:00.000Z",
    title: "Episodio 10",
    imageUrl: episode10,
    facebookTitle: null,
    facebookUrl: "https://facebook.com",
    twitterTitle: null,
    twitterUrl: "https://twitter.com",
    pinterestTitle: null,
    pinterestUrl: "https://pinterest.com",
    mastodonTitle: null,
    mastodonUrl: "https://mastodon.com",
  },
  {
    id: 2,
    publicationDate: "2020-10-18T11:00:00.000Z",
    title: "Episodio 9",
    imageUrl: episode9,
    facebookTitle: null,
    facebookUrl: "https://facebook.com",
    twitterTitle: null,
    twitterUrl: "https://twitter.com",
    pinterestTitle: null,
    pinterestUrl: "https://pinterest.com",
  },
  {
    id: 3,
    publicationDate: "2020-10-19T11:00:00.000Z",
    title: "Episodio 1",
    imageUrl: episode8,
    facebookTitle: null,
    facebookUrl: "https://facebook.com",
    twitterTitle: null,
    twitterUrl: "https://twitter.com",
    pinterestTitle: null,
    pinterestUrl: "https://pinterest.com",
  },
  {
    id: 4,
    publicationDate: "2020-10-18T11:00:00.000Z",
    title: "Episodio 2",
    imageUrl: episode7,
    facebookTitle: null,
    facebookUrl: "https://facebook.com",
    twitterTitle: null,
    twitterUrl: "https://twitter.com",
    pinterestTitle: null,
    pinterestUrl: "https://pinterest.com",
  },
  {
    id: 5,
    publicationDate: "2020-10-19T11:00:00.000Z",
    title: "Episodio 1",
    imageUrl: episode6,
    facebookTitle: null,
    facebookUrl: "https://facebook.com",
    twitterTitle: null,
    twitterUrl: "https://twitter.com",
    pinterestTitle: null,
    pinterestUrl: "https://pinterest.com",
  },
  {
    id: 6,
    publicationDate: "2020-10-18T11:00:00.000Z",
    title: "Episodio 2",
    imageUrl: episode5,
    facebookTitle: null,
    facebookUrl: "https://facebook.com",
    twitterTitle: null,
    twitterUrl: "https://twitter.com",
    pinterestTitle: null,
    pinterestUrl: "https://pinterest.com",
  },
  {
    id: 7,
    publicationDate: "2020-10-19T11:00:00.000Z",
    title: "Episodio 1",
    imageUrl: episode4,
    facebookTitle: null,
    facebookUrl: "https://facebook.com",
    twitterTitle: null,
    twitterUrl: "https://twitter.com",
    pinterestTitle: null,
    pinterestUrl: "https://pinterest.com",
  },
  {
    id: 8,
    publicationDate: "2020-10-18T11:00:00.000Z",
    title: "Episodio 2",
    imageUrl: episode3,
    facebookTitle: null,
    facebookUrl: "https://facebook.com",
    twitterTitle: null,
    twitterUrl: "https://twitter.com",
    pinterestTitle: null,
    pinterestUrl: "https://pinterest.com",
  },
  {
    id: 9,
    publicationDate: "2020-10-19T11:00:00.000Z",
    title: "Episodio 1",
    imageUrl: episode2,
    facebookTitle: null,
    facebookUrl: "https://facebook.com",
    twitterTitle: null,
    twitterUrl: "https://twitter.com",
    pinterestTitle: null,
    pinterestUrl: "https://pinterest.com",
  },
  {
    id: 10,
    publicationDate: "2020-10-18T11:00:00.000Z",
    title: "Episodio 2",
    imageUrl: episode1,
    facebookTitle: null,
    facebookUrl: "https://facebook.com",
    twitterTitle: null,
    twitterUrl: "https://twitter.com",
    pinterestTitle: null,
    pinterestUrl: "https://pinterest.com",
  },
  {
    id: 11,
    publicationDate: "2020-10-19T11:00:00.000Z",
    title: "Episodio 1",
    imageUrl: episode10,
    facebookTitle: null,
    facebookUrl: "https://facebook.com",
    twitterTitle: null,
    twitterUrl: "https://twitter.com",
    pinterestTitle: null,
    pinterestUrl: "https://pinterest.com",
  },
  {
    id: 12,
    publicationDate: "2020-10-18T11:00:00.000Z",
    title: "Episodio 2",
    imageUrl: episode9,
    facebookTitle: null,
    facebookUrl: "https://facebook.com",
    twitterTitle: null,
    twitterUrl: "https://twitter.com",
    pinterestTitle: null,
    pinterestUrl: "https://pinterest.com",
  },
  {
    id: 13,
    publicationDate: "2020-10-19T11:00:00.000Z",
    title: "Episodio 1",
    imageUrl: episode8,
    facebookTitle: null,
    facebookUrl: "https://facebook.com",
    twitterTitle: null,
    twitterUrl: "https://twitter.com",
    pinterestTitle: null,
    pinterestUrl: "https://pinterest.com",
  },
  {
    id: 14,
    publicationDate: "2020-10-18T11:00:00.000Z",
    title: "Episodio 2",
    imageUrl: episode7,
    facebookTitle: null,
    facebookUrl: "https://facebook.com",
    twitterTitle: null,
    twitterUrl: "https://twitter.com",
    pinterestTitle: null,
    pinterestUrl: "https://pinterest.com",
  },
  {
    id: 15,
    publicationDate: "2020-10-19T11:00:00.000Z",
    title: "Episodio 1",
    imageUrl: episode6,
    facebookTitle: null,
    facebookUrl: "https://facebook.com",
    twitterTitle: null,
    twitterUrl: "https://twitter.com",
    pinterestTitle: null,
    pinterestUrl: "https://pinterest.com",
  },
  {
    id: 16,
    publicationDate: "2020-10-18T11:00:00.000Z",
    title: "Episodio 2",
    imageUrl: episode5,
    facebookTitle: null,
    facebookUrl: "https://facebook.com",
    twitterTitle: null,
    twitterUrl: "https://twitter.com",
    pinterestTitle: null,
    pinterestUrl: "https://pinterest.com",
  },
  {
    id: 17,
    publicationDate: "2020-10-19T11:00:00.000Z",
    title: "Episodio 1",
    imageUrl: episode4,
    facebookTitle: null,
    facebookUrl: "https://facebook.com",
    twitterTitle: null,
    twitterUrl: "https://twitter.com",
    pinterestTitle: null,
    pinterestUrl: "https://pinterest.com",
  },
  {
    id: 18,
    publicationDate: "2020-10-18T11:00:00.000Z",
    title: "Episodio 2",
    imageUrl: episode3,
    facebookTitle: null,
    facebookUrl: "https://facebook.com",
    twitterTitle: null,
    twitterUrl: "https://twitter.com",
    pinterestTitle: null,
    pinterestUrl: "https://pinterest.com",
  },
  {
    id: 19,
    publicationDate: "2020-10-19T11:00:00.000Z",
    title: "Episodio 1",
    imageUrl: episode2,
    facebookTitle: null,
    facebookUrl: "https://facebook.com",
    twitterTitle: null,
    twitterUrl: "https://twitter.com",
    pinterestTitle: null,
    pinterestUrl: "https://pinterest.com",
  },
  {
    id: 20,
    publicationDate: "2020-10-18T11:00:00.000Z",
    title: "Episodio 2",
    imageUrl: episode1,
    facebookTitle: null,
    facebookUrl: "https://facebook.com",
    twitterTitle: null,
    twitterUrl: "https://twitter.com",
    pinterestTitle: null,
    pinterestUrl: "https://pinterest.com",
  },
];

export default publications;

import React, { useState, useEffect, useRef } from "react";
import ErrorBoundary from "common/components/ErrorBoundary";
import { moduleInfo } from "../../config";
import { moduleInfo as publicationModuleInfo } from "publication/config";
import { Link } from "react-router-dom";
import createBemNameGenerator from "common/helpers/createBemNameGenerator";
import _ from "lodash";

const Item = ({ id, imageUrl, title, setItemHeight, itemHeight }) => {
  require("." +
    __filename.substr(__filename.lastIndexOf("/")).replace(/.jsx$/i, ".scss"));

  const bemNameGenerator = createBemNameGenerator(
    moduleInfo.nameKebabCase,
    __filename
  );

  const [imageIsLoaded, setImageIsLoaded] = useState(false);
  const mainDivRef = useRef(null);

  const calculateHeight = (width) => {
    const itemFullHeight = (width * 1730) / 1159;
    const itemReducedHeight = itemFullHeight - (69 * itemFullHeight) / 1730;
    return _.floor(itemReducedHeight);
  };

  useEffect(() => {
    if (!!setItemHeight) {
      window.onresize = () => {
        if (mainDivRef.current) {
          setItemHeight(calculateHeight(mainDivRef.current.clientWidth));
        }
      };
    }

    return () => {
      if (!!setItemHeight) {
        window.onresize = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!setItemHeight && mainDivRef.current) {
      setItemHeight(calculateHeight(mainDivRef.current.clientWidth));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainDivRef.current, imageIsLoaded]);

  return (
    <ErrorBoundary>
      <Link
        className={bemNameGenerator()}
        ref={mainDivRef}
        style={{ height: itemHeight }}
        to={publicationModuleInfo.path.replace(":id", id)}
      >
        <img
          loading={"lazy"}
          src={imageUrl}
          alt={title}
          className={bemNameGenerator("image", [
            imageIsLoaded ? "loaded" : "unloaded",
          ])}
          onLoad={() => setImageIsLoaded(true)}
        />
      </Link>
    </ErrorBoundary>
  );
};

export default Item;

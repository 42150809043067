import React from "react";
import ErrorBoundary from "common/components/ErrorBoundary";
import { moduleInfo } from "../../config";
import createBemNameGenerator from "common/helpers/createBemNameGenerator";
import Navigation from "./Navigation";
import SocialNetworks from "./SocialNetworks";
import BackButton from "./BackButton";
import moment from "moment";

const Info = ({ currentItemNumber = 0, publications = [] }) => {
  require("." +
    __filename.substr(__filename.lastIndexOf("/")).replace(/.jsx$/i, ".scss"));

  const bemNameGenerator = createBemNameGenerator(
    moduleInfo.nameKebabCase,
    __filename
  );

  const { publicationDate, title, ...restOfData } = publications[
    currentItemNumber
  ];

  const publicationDateMoment = moment(publicationDate);

  let publicationDatePreviousText = "Published ";
  let publicationDateFormatted;

  if (moment().diff(publicationDateMoment, "days") < 2) {
    publicationDateFormatted = publicationDateMoment.startOf("day").fromNow();
  } else {
    publicationDatePreviousText += "on ";
    publicationDateFormatted = publicationDateMoment.format("dddd, LL");
  }

  return (
    <ErrorBoundary>
      <div className={bemNameGenerator()}>
        <h1 className={bemNameGenerator("title")}>{title}</h1>
        <br />
        <p className={bemNameGenerator("publication-date")}>
          <span className={bemNameGenerator("publication-date-previous-text")}>
            {publicationDatePreviousText}
          </span>
          {publicationDateFormatted}
        </p>
        <SocialNetworks
          data={restOfData}
          className={bemNameGenerator("social-networks")}
        />
        <Navigation
          currentItemNumber={currentItemNumber}
          publications={publications}
          className={bemNameGenerator("navigation")}
        />
        <BackButton className={bemNameGenerator("back-button")} />
      </div>
    </ErrorBoundary>
  );
};

export default Info;

import path from "path";
import _ from "lodash";

export const relativePath = path.relative(process.cwd(), __dirname);
export const id = relativePath.split("/")[1];

export const moduleInfo = {
  name: id,
  nameKebabCase: _.kebabCase(id),
  label: "About",
  path: "/" + id,
  isExact: true,
};

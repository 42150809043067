import path from "path";
import _ from "lodash";
import { faRssSquare } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookSquare,
  faTwitter,
  faPinterest,
  faTelegramPlane,
  faMastodon,
} from "@fortawesome/free-brands-svg-icons";

export const relativePath = path.relative(process.cwd(), __dirname);
export const id = relativePath.split("/")[1];

export const moduleInfo = {
  name: id,
  nameKebabCase: _.kebabCase(id),
  label: "Core",
};

export const navigationConfig = ["homePage", "about"];

export const socialNetworkLinks = {
  facebook: {
    link: "https://www.facebook.com/CoolansPlanet",
    icon: faFacebookSquare,
    title: "Facebook",
  },
  twitter: {
    link: "https://twitter.com/coolansplanet",
    icon: faTwitter,
    title: "Twitter",
  },
  pinterest: {
    link: "https://www.pinterest.com/coolansplanet/coolans-planet",
    icon: faPinterest,
    title: "Pinterest",
  },
  telegram: {
    link: "https://t.me/coolansplanet",
    icon: faTelegramPlane,
    title: "Telegram",
  },
  mastodon: {
    link: "https://mastodon.social/@coolansplanet",
    icon: faMastodon,
    title: "Mastodon",
  },
  rss: {
    link: "feed/rss",
    icon: faRssSquare,
    title: "RSS Channel",
    type: "application/rss+xml",
  },
};

export const apiUrl = "/api";

export const getPublicationsUrl = apiUrl + "/publications";

export const getAboutPageDataUrl = apiUrl + "/about-page";

export const getHomePageCoverUrl = apiUrl + "/homepage-cover";

export const getFooterDataUrl = apiUrl + "/footer";

export const errorPageData = {
  path: "*",
  isExact: true,
  title: "404",
  subtitle: "WRONG URL",
};

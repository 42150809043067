/* eslint eqeqeq: 0 */
import React, { useState, useEffect } from "react";
import ErrorBoundary from "common/components/ErrorBoundary";
import { moduleInfo } from "../../config";
import createBemNameGenerator from "common/helpers/createBemNameGenerator";
import { getPublications } from "core/provider";
import { useParams } from "react-router-dom";
import ErrorPage from "core/components/ErrorPage";
import Image from "../Image";
import Info from "../Info";

const Publication = () => {
  require("." +
    __filename.substr(__filename.lastIndexOf("/")).replace(/.jsx$/i, ".scss"));

  const bemNameGenerator = createBemNameGenerator(
    moduleInfo.nameKebabCase,
    __filename
  );

  const { id } = useParams();
  const [publications, setPublications] = useState([]);
  const [isLoadingPublications, setIsLoadingPublications] = useState(false);
  const [currentItemNumber, setCurrentItemNumber] = useState(0);

  useEffect(() => {
    setIsLoadingPublications(true);
    getPublications()
      .then(({ data }) => {
        const index = data.findIndex(({ id: oneId }) => oneId == id);
        setCurrentItemNumber(index);
        setIsLoadingPublications(false);
        setPublications(data);
      })
      .catch((error) => {
        setIsLoadingPublications(false);
        alert("Error: failed connection with the server");
      });
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (publications.length > 0) {
      const index = publications.findIndex(({ id: oneId }) => oneId == id);
      setCurrentItemNumber(index);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <ErrorBoundary>
      <div className={bemNameGenerator()}>
        {isLoadingPublications && (
          <h3 className={bemNameGenerator("loading-text")}>Loading...</h3>
        )}
        {!!publications[currentItemNumber] && (
          <div className={bemNameGenerator("content")}>
            <Image url={publications[currentItemNumber].imageUrl} />
            <Info
              currentItemNumber={currentItemNumber}
              publications={publications}
            />
          </div>
        )}
        {currentItemNumber < 0 && <ErrorPage />}
      </div>
    </ErrorBoundary>
  );
};

export default Publication;

import coverImage from "./cover.png";

const cover = {
  id: 1,
  title: "",
  text: null,
  imageUrl: coverImage,
};

export default cover;

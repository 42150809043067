const fakeApiFactory = (data) => {
  return () =>
    new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ data });
      }, 300);
    });
};

export default fakeApiFactory;

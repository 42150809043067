import React from "react";
import ErrorBoundary from "common/components/ErrorBoundary";
import { moduleInfo, errorPageData } from "../../config";
import createBemNameGenerator from "common/helpers/createBemNameGenerator";

const ErrorPage = () => {
  require("." +
    __filename.substr(__filename.lastIndexOf("/")).replace(/.jsx$/i, ".scss"));

  const bemNameGenerator = createBemNameGenerator(
    moduleInfo.nameKebabCase,
    __filename
  );

  const { title, subtitle } = errorPageData;

  return (
    <ErrorBoundary>
      <div className={bemNameGenerator()}>
        <h1 className={bemNameGenerator("title")}>{title}</h1>
        <p className={bemNameGenerator("subtitle")}>{subtitle}</p>
      </div>
    </ErrorBoundary>
  );
};

export default ErrorPage;

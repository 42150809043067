import React, { useState } from "react";
import ErrorBoundary from "common/components/ErrorBoundary";
import { moduleInfo } from "../../config";
import createBemNameGenerator from "common/helpers/createBemNameGenerator";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";

const BurgerMenu = ({
  navigationLinks = [],
  socialNetworkLinks = [],
  className = "",
}) => {
  require("." +
    __filename.substr(__filename.lastIndexOf("/")).replace(/.jsx$/i, ".scss"));

  const bemNameGenerator = createBemNameGenerator(
    moduleInfo.nameKebabCase,
    __filename
  );

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const toggleMenu = () => {
    setMenuIsOpen(!menuIsOpen);
  };

  return (
    <ErrorBoundary>
      <div className={bemNameGenerator() + " " + className}>
        <button
          className={bemNameGenerator("burger-button")}
          onClick={() => toggleMenu()}
        >
          <FontAwesomeIcon icon={faBars} />
        </button>
        {menuIsOpen && (
          <div
            className={bemNameGenerator("menu-items")}
            onClick={() => setMenuIsOpen(false)}
          >
            {navigationLinks.map(({ name, path, label, isExact }) => {
              const props = { key: name, to: path, exact: isExact };
              return (
                <NavLink
                  {...props}
                  className={bemNameGenerator("link", ["navigation"])}
                >
                  {label}
                </NavLink>
              );
            })}
            <div className={bemNameGenerator("social-network-links")}>
              {Object.entries(socialNetworkLinks).map(
                ([key, { icon, link, title, type }]) => {
                  const linkProps = {
                    href: link,
                    key,
                    title,
                    type,
                    target: "_blank",
                    rel: "noopener noreferrer",
                  };

                  !linkProps.type && delete linkProps.type;

                  return (
                    <a
                      {...linkProps}
                      className={bemNameGenerator("link", ["social-network"])}
                    >
                      <FontAwesomeIcon icon={icon} />
                    </a>
                  );
                }
              )}
            </div>
          </div>
        )}
      </div>
    </ErrorBoundary>
  );
};

export default BurgerMenu;

import React, { useState, useEffect } from "react";
import ErrorBoundary from "common/components/ErrorBoundary";
import { moduleInfo } from "../../config";
import createBemNameGenerator from "common/helpers/createBemNameGenerator";
import { getFooterData } from "core/provider";

const Footer = () => {
  require("." +
    __filename.substr(__filename.lastIndexOf("/")).replace(/.jsx$/i, ".scss"));

  const bemNameGenerator = createBemNameGenerator(
    moduleInfo.nameKebabCase,
    __filename
  );

  const [footerText, setFooterText] = useState("");
  useEffect(() => {
    getFooterData()
      .then(({ data }) => {
        setFooterText(data.text);
      })
      .catch((error) => {
        setFooterText("");
        alert("Error: failed connection with the server");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorBoundary>
      <div className={bemNameGenerator()}>{footerText}</div>
    </ErrorBoundary>
  );
};

export default Footer;

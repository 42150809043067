import React from "react";
import ErrorBoundary from "common/components/ErrorBoundary";
import { moduleInfo, backButton } from "../../config";
import { moduleInfo as homePageModuleInfo } from "homePage/config";
import createBemNameGenerator from "common/helpers/createBemNameGenerator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const BackButton = ({ className = "" }) => {
  require("." +
    __filename.substr(__filename.lastIndexOf("/")).replace(/.jsx$/i, ".scss"));

  const bemNameGenerator = createBemNameGenerator(
    moduleInfo.nameKebabCase,
    __filename
  );
  const { title, icon } = backButton;
  return (
    <ErrorBoundary>
      <Link
        className={bemNameGenerator() + " " + className}
        to={homePageModuleInfo.path}
      >
        <span className={bemNameGenerator("text")}>{title + " "}</span>
        <FontAwesomeIcon icon={icon} className={bemNameGenerator("icon")} />
      </Link>
    </ErrorBoundary>
  );
};

export default BackButton;

import React, { useState, useEffect } from "react";
import ErrorBoundary from "common/components/ErrorBoundary";
import { moduleInfo } from "../../config";
import createBemNameGenerator from "common/helpers/createBemNameGenerator";
import { getHomePageCover, getPublications } from "core/provider";
import Item from "../Item";

const HomePage = () => {
  require("." +
    __filename.substr(__filename.lastIndexOf("/")).replace(/.jsx$/i, ".scss"));

  const bemNameGenerator = createBemNameGenerator(
    moduleInfo.nameKebabCase,
    __filename
  );
  const [coverImageUrl, setCoverImageUrl] = useState("");
  const [coverImageIsLoaded, setCoverImageIsLoaded] = useState(false);
  const [publications, setPublications] = useState([]);
  const [isLoadingPublications, setIsLoadingPublications] = useState(false);
  const [itemHeight, setItemHeight] = useState(0);

  useEffect(() => {
    setIsLoadingPublications(true);
    getPublications()
      .then(({ data }) => {
        setIsLoadingPublications(false);
        setPublications(data);
      })
      .catch((error) => {
        setIsLoadingPublications(false);
        alert("Error: failed connection with the server");
      });
    getHomePageCover()
      .then(({ data }) => {
        setCoverImageUrl(data.imageUrl);
      })
      .catch((error) => {
        alert("Error: failed connection with the server");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorBoundary>
      <div className={bemNameGenerator()}>
        {isLoadingPublications && (
          <h3 className={bemNameGenerator("loading-text")}>Loading...</h3>
        )}
        {!!coverImageUrl && (
          <div className={bemNameGenerator("cover")}>
            <img
              className={bemNameGenerator("cover-image", [
                coverImageIsLoaded ? "loaded" : "unloaded",
              ])}
              onLoad={() => setCoverImageIsLoaded(true)}
              src={coverImageUrl}
              alt=""
            />
          </div>
        )}
        <div className={bemNameGenerator("publications")}>
          {publications.map(({ id, title, imageUrl }, index) => {
            const itemProps = {
              id,
              key: id,
              imageUrl,
              alt: title,
              itemHeight,
            };
            index === 0 && (itemProps["setItemHeight"] = setItemHeight);
            return <Item {...itemProps} />;
          })}
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default HomePage;
